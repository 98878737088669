/* Reset some basic default styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../src/components/images/background\ image\ MMT.jpeg');
  background-size: cover;
  background-position: center;
  color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Make images responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Ensure body fits all screen sizes */
html, body {
  height: 100%;
}

/* Footer Section */
.footer {
  padding: 10px 0;
  background-color: #4c51bf;
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
}