html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Main container takes up remaining height, pushing the footer down */
.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.home-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1000px; /* More responsive max-width */
  margin: 0 auto;
  color: #f5f5f5b9;
  min-height: calc(100vh - 60px); /* Adjust the value as needed */
}

/* Hero Section */
.hero {
  margin-bottom: 40px;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

/* Adjusted for better readability */
p {
  font-size: 1.1rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 20px;
}

/* Call-to-action buttons */
.cta-buttons {
  margin: 20px 0;
}

.cta-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1.1rem;
  color: white;
  background-color: #4c51bf;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0e1148;
}

/* Features section - Flexbox for better layout on larger screens */
.features {
  display: flex;
  flex-direction: column; /* Stack for smaller screens */
  gap: 20px;
  margin: 40px 0;
}

.feature {
  width: 100%; /* Full width on smaller screens */
  text-align: left;
}

.feature h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.feature p {
  color: #ccc;
}

/* Footer Section */
.footer {
  padding: 10px 0;
  background-color: #4c51bf;
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
}

.social-links {
  display: flex;
  gap: 10px;
}

.social-links a {
  position: relative;
  padding: 0 10px;
  /* margin: 0 10px; */
  text-decoration: none;
  font-size: 1rem;
  color: #f1f1f1;
  transition: color 0.3s ease;
  /* background-color: green; */
  border-radius: 5px;
}

.social-links a:hover {
  color: #f1f1f1;
  background-color: #0e1148;
}

/* Add vertical line to all links except the last one */
.social-links a:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px; /* Adjust height of the vertical line */
  border-right: 1px solid #0e1148; /* Line color */
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
  .features {
    flex-direction: row; /* Side-by-side on larger screens */
  }

  .feature {
    width: 45%; /* Reduce feature width on large screens */
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
