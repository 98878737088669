.roadmap-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  section {
    margin-bottom: 30px;
  }

  section h2 {
    /* color: red; */
    font-size: 0.9em;
  }

  section p {
    /* color: green; */
    font-size: 0.8em;
  }

 
  
  h2 {
    font-size: 1.8em;
    /* color: #ff6347; */
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  