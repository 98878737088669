.community-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: whitesmoke;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    display: inline-block;
    margin: 0 15px;
  }
  
  a {
    text-decoration: none;
    font-size: 1.2em;
    color: #1e90ff;
  }
  
  a:hover {
    color: #ff6347;
  }
  
  h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    /* color: #20b2aa; */
  }
  
  p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  