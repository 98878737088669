.tokenomics-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  section {
    margin-bottom: 30px;
  }
  
  ul {
    list-style-type: square;
    margin: 10px 0;
    padding-left: 20px;
  }
  
  li {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    /* color: #32cd32; */
  }
  
  p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  