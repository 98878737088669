.whitepaper-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  color: whitesmoke;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

p {
  font-size: 1.1em;
  line-height: 1.6;
}

section {
  margin-bottom: 30px;
}

.executive-summary, .introduction, .problem-solution, .technology-architecture,
.tokenomics, .roadmap, .team, .partnerships, .conclusion {
  /* Add specific styles for each section as needed */
}

/* Responsive design */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
}